<template>
  <LoadingScreen v-if="!$store.state.creatorPage" />
  <div>
    <TsHero />
    <div>
      <BrandsLogos />
    </div>
    <div class="ts-container-fluid container-fluid">
      <HowItWorks />
    </div>
    <div class="ts-container-fluid container-fluid">
      <CreatorsReviews>
        <h1
          class="ts-fs-1 text-center ts-text-gray-5 fw-bold mb-07 mb-lg-13"
          v-if="$store.state.creatorPage"
        >
          {{ $store.state.creatorPage.Testimonial.title }}
          <span class="ts-text-primary-1">
            {{ $store.state.creatorPage.Testimonial.titleHighlighted }}</span
          >
          {{ $store.state.creatorPage.Testimonial.title2 }}
        </h1>
      </CreatorsReviews>
      <LastestNews>
        <h1
          class="ts-fs-1 ts-text-gray-5 text-center fw-bold mb-08 mb-lg-13"
          v-if="$store.state.creatorPage"
        >
          {{ $store.state.creatorPage.BlogSectionTitle }}
        </h1>
      </LastestNews>
    </div>
  </div>
</template>

<script>
import LoadingScreen from "../components/Common/LoadingScreen.vue";
import TsHero from "../components/Creators/TsHero.vue";
import BrandsLogos from "../components/Common/BrandsLogos.vue";
import HowItWorks from "../components/Creators/HowItWorks.vue";
import CreatorsReviews from "../components/Common/CreatorsReviews.vue";
import LastestNews from "../components/Common/LastestNews.vue";

export default {
  name: "CreatorsPage",
  components: {
    LoadingScreen,
    TsHero,
    BrandsLogos,
    HowItWorks,
    CreatorsReviews,
    LastestNews,
  },

  computed: {
    activeLanguage() {
      return this.$store.getters.activeLanguage;
    },
  },

  // Watch if ACtive language Change but not same as old Language
  watch: {
    activeLanguage(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch("getCreatorPage");
      }
    },
  },
  created() {
    this.$store.dispatch("getCreatorPage");
  },
};
</script>
